import { ErrorNotifier, errorDataBuilder } from './error-notifier';
import axios, { AxiosRequestConfig } from 'axios';
import { logError, logInfo } from './logger';

import { UserPayload } from '../../api/src/types';

export const updateUser = async (payload: UserPayload, token: string) => {
  console.log('INDENTITY CLIENT PAYLOAD ===> ', payload);
  const options: AxiosRequestConfig = {
    url: `${process.env.REACT_APP_IDENTITY_API_URL}/update-user-profile`,
    method: 'PATCH',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    data: payload,
    responseType: 'json',
  };

  try {
    const response = await axios.request(options);
    logInfo('Response', response);
    return response.data;
  } catch (e: any) {
    ErrorNotifier.notify(e, errorDataBuilder('API', 400, e));
    logError(e.message, { error: e });
    throw e;
  }
};

export const testEmail = async (email: string) => {
  const options: AxiosRequestConfig = {
    url: `/api/validate-email`,
    method: 'POST',
    responseType: 'json',
    data: { email },
  };

  const response = await axios.request<{ status: boolean; clientIP: string }>(options);
  return response.data;
};

export const sendPasswordResetEmailUserWithSocialAuth0Only = async (email: string) => {
  const options: AxiosRequestConfig = {
    url: `${process.env.REACT_APP_IDENTITY_API_URL}/send-password-reset-email-for-social-auth0-only-user`,
    method: 'POST',
    responseType: 'json',
    data: { email },
  };

  const response = await axios.request<{ status: boolean; clientIP: string }>(options);
  return response.data;
};
